body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, sans-serif;
  background-color: #485922;
  color: #EAEAEA;
}
input[type="submit"]{
  background-color: transparent;
  color: #EAEAEA;
  border: 2px solid #B4BF5E;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
  margin-top: 5px;
}
a{
  color: #B4BF5E;
}
#root{
  margin: 20px auto;
  position: relative;
  width: 80%;
  max-width: 400px;
}
.list-commands a{
  font-size: 26px!important;
  margin-right: 15px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
.items{
  padding-left: 0px;
  list-style: none;
}
.items li{
  border: 1px solid #B4BF5E;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.items li .fa{
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px!important;
  color: #B4BF5E;
  float: right;
}
.items h4{
  margin: 0px;
}
input[type="text"]{
  border-radius: 5px;
  color: #485922;
  background-color: #B4BF5E;
  padding: 5px;
  border: none;
}
.add{
  margin-bottom: 5px;
}
.add-form label{
  width: 20%;
  display: inline-block;
  margin-bottom: 3px;
}
.add-form input[type="text"]{
  width: 75%;
  margin-bottom: 3px;
}
.new-list-name{
  margin: 5px 0px 2px 0px;
}
.list-list{
  list-style: none;
  padding: 0;
  margin: 0px 0px 15px 0px;
  text-align: center
}
.list-list li{
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #B4BF5E;
  padding: 5px;
}
.list-list a{
  text-decoration: none;
  color: #EAEAEA;
}
a:hover{
  opacity: 0.8;
}
.lists-page{
  text-align: center;
}
.lists-page form {
  text-align: left;
}
